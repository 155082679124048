import { Col, Row, Result, Card } from "antd";
import Header from "Components/Header";
import Context from "Context/Ctx";
import { useContext, useEffect } from "react";
import { sendError } from "Util";

const Fail = () => {
  const { error, payload } = useContext(Context);

  useEffect(() => {
    console.log({ ...error, payload });
    sendError({ ...error, payload });
  }, []);

  return (
    <Row>
      <Col span={8} offset={8}>
        <Card bordered={false} cover={<Header />}>
          <Result
            status="error"
            title="Identidad no validada."
            subTitle={error?.message}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Fail;
