export const sendToken = (token) => {
  if (window.opener) {
    window.opener.postMessage(JSON.stringify({ token }), "*");
  }
};
export const sendError = (error) => {
  if (window.opener) {
    window.opener.postMessage(JSON.stringify(error), "*");
  }
};
