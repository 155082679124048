const mask = require("Assets/mask.png");
const logo = require("Assets/logo.png");

const Header = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <img 
      src={logo}
      style={{
        width: "75%",
        height: "75%",
        marginTop: '30px'
      }}
    />
  </div>
  // <div style={{ position: "relative" }} className="header">
  //   <img src={mask} style={{ width: "100%" }} />
  //   <img
  //     src={logo}
  //     style={{
  //       width: "40%",
  //       position: "absolute",
  //       top: 16,
  //       left: "50%",
  //       marginLeft: "-20%",
  //     }}
  //   />
  // </div>
);

export default Header;
