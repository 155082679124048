import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Radio,
  Space,
  Spin,
  Col,
  Row,
  Tooltip,
  InputNumber,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Header from "Components/Header";
import Context from "Context/Ctx";
import { useContext, useEffect, useState } from "react";
import { Axios } from "Util/Axios";
import moment from "moment";
import Timer from "Components/Timer";
import { sendToken } from "Util";

const Otp = () => {
  const { otp, setOtp, setIda, setPaso, setError } = useContext(Context);
  const { phone, ApplicationId, xyz } = otp;
  const [cargando, setCargando] = useState(false);
  const [reenviando, setReenviando] = useState(false);
  const [conteo, setConteo] = useState(null);
  const [puedeEnviarPin, setPuedeEnviarPin] = useState(true);
  const [puedeReenviarPin, setPuedeReenviarPin] = useState(true);
  const [styleTimer, setStyleTimer] = useState({});
  const { transactionId = null } = otp;

  const reenviar = () => {
    setCargando(true);
    setReenviando(true);

    Axios.post("/reenviar-pin", { ApplicationId, phone, transactionId })
      .then(({ data }) => {
        if (data.action == "Error") {
          notification.error({ message: data.message });
          return;
        }
        setPuedeEnviarPin(true);
        setPuedeReenviarPin(false);
        setOtp({ ...otp, xyz: data.xyz });
        notification.success({
          message: "Ingrese el PIN reenviado por mensaje de voz",
        });
      })
      .catch((error) => {
        notification.error({ message: error.toString() });
      })
      .finally(() => {
        setTimeout(() => {
          setCargando(false);
          setReenviando(false);
        }, 2000);
      });
  };

  const verificar = (data) => {
    setCargando(true);
    Axios.post("/verificar-pin", { ...data, transactionId, ApplicationId })
      .then(({ data }) => {
        if (data.action == "Error") {
          if (
            [
              100, 101, 103, 104, 105, 106, 107, 401, 403, 404, 405, 406, 407,
              700, 302, 303, 304,
            ].includes(data.code)
          ) {
            setError(data);
            setPaso("Fail");
          }
          notification.error({ message: data.message });
          return;
        } else {
          sendToken(data.token);
          setPaso("Success");
        }
      })
      .catch((error) => {
        notification.error({ message: error.toString() });
      })
      .finally(() => {
        setCargando(false);
      });
  };

  const onTick = (seconds) => {
    setConteo(seconds);
  };

  const iniciarIda = () => {
    setCargando(true);
    setReenviando(true);
    Axios.post("/obtener-questionario", { ApplicationId, xyz })
      .then(({ data }) => {
        if (data.action == "IDA") {
          setIda({ ...data, ApplicationId });
          setPaso("IDA");
          return;
        }
        if (data.action == "Error") {
          if (
            [
              100, 101, 103, 104, 105, 106, 107, 401, 403, 404, 405, 406, 407,
              700, 302, 303, 304,
            ].includes(data.code)
          ) {
            setError(data);
            setPaso("Fail");
          }
        }
        if (data.action == "Fail") {
          setError(data);
          setPaso("Fail");
        }
      })
      .catch((error) => {
        notification.error({ message: error.toString() });
      })
      .finally(() => {
        setCargando(false);
        setReenviando(false);
      });
  };

  useEffect(() => {
    if (!conteo) {
      return;
    }
    if (conteo == 31) {
      setPuedeEnviarPin(false);
    }
    if (conteo < 40 && conteo > 20) {
      setStyleTimer({ color: "#D68910" });
    } else if (conteo < 21) {
      setStyleTimer({ color: "#BA4A00" });
    } else {
      setStyleTimer({ color: "#F8F9F9" });
    }
    if (conteo < 0) {
      setStyleTimer({ ...styleTimer, display: "none" });
    }
    if (conteo < -60) {
      setError({
        code: 999,
        message:
          "Tiempo de espera agotado, por favor inicia nuevamente el proceso.",
      });
      setPaso("Fail");
    }
  }, [conteo]);

  return (
    <Row>
      <Col span={10} offset={8}>
        <Card bordered={false} cover={<Header />}>
          <Spin spinning={cargando}>
            <center>
              <Timer
                onTick={onTick}
                startSeconds={120}
                pause={reenviando}
                type={-1}
                style={styleTimer}
              />

              {conteo && conteo <= 31 && conteo > 1 && puedeReenviarPin ? (
                <>
                  <Typography.Paragraph>
                    ¿No recibiste el pin?
                  </Typography.Paragraph>
                  <Button block onClick={reenviar} type="primary">
                    Intenta con un mensaje de voz
                  </Button>
                </>
              ) : null}
              {conteo && conteo <= 0 ? (
                <>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={require("Assets/faq.png")}
                  />
                  <Button block onClick={iniciarIda} type="primary">
                    Verifica tu identidad a través de preguntas
                  </Button>
                </>
              ) : null}
            </center>

            {conteo && conteo > 0 && puedeEnviarPin ? (
              <Form layout="vertical" onFinish={verificar}>
                <Form.Item
                  className="pin"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el pin enviado a su celular",
                    },
                  ]}
                  name="pin"
                  style={{ marginBottom: 8 }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Escribir código"
                    suffix={
                      <Tooltip title="Extra information">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
                <span style={{ color: "#F8F9F9" }}>
                  Ingrese el código enviado a su teléfono
                </span>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  style={{ marginTop: 24 }}
                >
                  Validar
                </Button>
              </Form>
            ) : null}
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default Otp;
