import { Col, Row, Result, Card } from "antd";
import Header from "Components/Header";
const ok = require("Assets/check-circle.png");

const Success = () => {
  return (
    <Row>
      <Col span={8} offset={8}>
        <Card bordered={false} cover={<Header />}>
          <Result
            icon={<img src={ok} width="42" />}
            status="success"
            title="Identidad validada exitosamente!"
            subTitle=""
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Success;
