import { Spin, Typography } from "antd";
import Context from "Context/Ctx";
import { useContext, useEffect } from "react";
import DatosDeEntrada from "steps/DatosDeEntrada";
import Fail from "steps/Fail";
import Ida from "steps/Ida";
import Otp from "steps/Otp";
import Success from "steps/Success";
import { sendToken } from "Util";

const Main = () => {
  const { paso, mensajeError = "" } = useContext(Context);

  useEffect(() => {
    // sendToken("test4");
  }, []);

  if (paso == "validando-token") {
    return (
      <Spin spinning={true}>
        <Typography.Title align="center">
          Validando APP solution
        </Typography.Title>
        <Typography.Paragraph align="center">
          Espere un momento por favor...
        </Typography.Paragraph>
      </Spin>
    );
  }

  if (paso == "datos-de-entrada") {
    return <DatosDeEntrada />;
  }
  if (paso == "IDA") {
    return <Ida />;
  }
  if (paso == "OTP") {
    return <Otp />;
  }
  if (paso == "Success") {
    return <Success />;
  }
  if (paso == "Fail") {
    return <Fail />;
  }
  return "";
};

export default Main;
