import { useEffect, useState } from "react";
import API from "Util/Axios";
import Context from "./Ctx";

const EstadoGeneral = ({ children }) => {
  const [paso, setPaso] = useState("validando-token");
  const [ida, setIda] = useState({});
  const [otp, setOtp] = useState({});
  const [error, setError] = useState({});
  const [payload, setPayload] = useState({});
  const [mensajeError, setMensajeError] = useState("");

  const validateToken = () => {
    if (process.env.REACT_APP_ENV == "dev") {
      setPaso("datos-de-entrada");
      //datos-de-entrada
      return;
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (!params.publicKey) {
      setMensajeError("App token not found");
      setPaso("Fail");
      return;
    }

    //document.referrer
    if (document.referrer == "") {
      setMensajeError("Access restring");
      setPaso("Fail");
      return;
    }

    API.post("/valid-public-key?publicKey=" + params.publicKey, {
      referer: document.referrer,
    })
      .then(({ data }) => {
        setPaso("datos-de-entrada");
      })
      .catch((error) => {
        setMensajeError(error?.response?.data || error.toString());
        setPaso("Fail");
      });
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <Context.Provider
      value={{
        paso,
        setPaso,
        ida,
        setIda,
        otp,
        setOtp,
        mensajeError,
        setMensajeError,
        error,
        setPayload,
        payload,
        setError,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default EstadoGeneral;
