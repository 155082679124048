import {
  Card,
  Input,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Spin,
  Row,
  Col,
  notification,
} from "antd";
import Header from "Components/Header";
import Context from "Context/Ctx";
import { useContext, useEffect, useState } from "react";
import { Axios } from "Util/Axios";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

const DatosDeEntrada = () => {
  const { setIda, setOtp, setPaso, setError, setPayload } = useContext(Context);
  const [cargando, setCargando] = useState(false);
  const [form] = useForm();

  const validateNumber = (_, value) => {
    if (value >= 3000000000 && value <= 3999999999) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Número de celular invalido"));
  };

  const validateId = (_, value) => {
    if (value >= 100000 && value <= 1999999999) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Número de identificación invalido"));
  };

  const validate = (payload) => {
    setCargando(true);

    setPayload({
      ...payload,
      IdExpeditionDate: payload.IdExpeditionDate.format("YYYY-MM-DD"),
    });
    Axios.post("validate", payload)
      .then(({ data }) => {
        if (data.action == "Error") {
          if (
            [
              100, 101, 103, 104, 105, 106, 107, 401, 403, 404, 405, 406, 407,
              700, 302, 303, 304,
            ].includes(data.code)
          ) {
            setError(data);
            //setMensajeError(data.message);
            setPaso("Fail");
          }
          notification.error({ message: data.message });
          return;
        }
        if (data.action == "IDA") {
          setIda(data);
          setPaso("IDA");
        } else if (data.action == "OTP") {
          setOtp(data);
          setPaso("OTP");
        } else {
          notification.error({ message: data.message });
        }
      })
      .catch((error) => {
        notification.error({ message: error.toString() });
      })
      .finally(() => {
        setCargando(false);
      });
  };

  useEffect(() => {
    if (form) {
      if (process.env.REACT_APP_ENV == "dev") {
        form.setFieldsValue({
          IdNumber: "1075217313",
          IdType: 1,
          LastName: "REYES",
          IdExpeditionDate: moment("2005-07-01"),
          RecentPhoneNumber: 3136463539,
        });
      }
    }
  }, [form]);

  useEffect(() => {
    setError({});
  }, []);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
    }}>
      <Card 
        bordered={false} 
        cover={<Header />}
        style={{
          width: "30vw",
        }}
      >
        <Spin spinning={cargando}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              IdType: "",
              IdNumber: "",
              LastName: "",
              IdExpeditionDate: "",
              RecentPhoneNumber: "",
            }}
            onFinish={validate}
          >
            <Form.Item
              label="Primer apellido"
              name="LastName"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tipo de identificación"
              name="IdType"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="">Seleccione</Select.Option>
                <Select.Option value={1}>Cédula</Select.Option>
                <Select.Option value={3}>
                  Cédula de extranjeria
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Numero de identificación"
              name="IdNumber"
              rules={[{ required: true, validator: validateId }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Fecha de expedición"
              name="IdExpeditionDate"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Número de celular"
              name="RecentPhoneNumber"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                  validator: validateNumber,
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Button htmlType="submit" type="primary" block>
              Validar
            </Button>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default DatosDeEntrada;
