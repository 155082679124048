import "antd/dist/antd.css";
import "App.css";
import EstadoGeneral from "Context";
import Main from "Main";

function App() {
  console.log(
    `%c Env ${process.env.REACT_APP_ENV}`,
    "background: #222; color: #bada55"
  );
  return (
    <div
      style={{
        display: "flex",
        "align-items": "center",
        "min-height": "100vh",
      }}
    >
      <EstadoGeneral>
        <Main />
      </EstadoGeneral>
    </div>
  );
}

export default App;
