import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Radio,
  Space,
  Spin,
  Steps,
  Col,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Header from "Components/Header";
import Context from "Context/Ctx";
import { useContext, useEffect, useState } from "react";
import { sendToken } from "Util";
import { Axios } from "Util/Axios";

const { Step } = Steps;

const Ida = () => {
  const [form] = useForm();
  const { ida, setError, setPaso } = useContext(Context);
  const [current, setCurrent] = useState(0);
  const [cargando, setCargando] = useState(false);
  const { _q = [], _qx = [], ApplicationId } = ida;

  const send = (data) => {
    setCargando(true);
    const { transactionId } = ida;
    Axios.post("/responder-questionario", { ...data, transactionId })
      .then(({ data }) => {
        if (data.action == "Success") {
          sendToken(data.token);
          setPaso("Success");
          return;
        }

        if (data.action == "Error") {
          if (
            [
              100, 101, 103, 104, 105, 106, 107, 401, 403, 404, 405, 406, 407,
              700, 302, 303, 304,
            ].includes(data.code)
          ) {
            setError(data);
            setPaso("Fail");
          }
          notification.error({ message: data.message });
          return;
        }
      })
      .finally(() => {
        setCargando(false);
      });
  };
  const label = (index) => {
    return _qx[index]?.t || "...";
  };
  const rules = (index) => {
    if (index == current) {
      return [
        {
          required: true,
          message: "Conteste la pregunta por favor.",
        },
      ];
    }
    return [];
  };
  const options = (index) => {
    const answer = _qx[index]?.Answer || [];
    return answer.map((a, i) => (
      <Radio key={i} value={i}>
        {a.t}
      </Radio>
    ));
  };

  const next = () => {
    if (form.getFieldValue(["questions", current, "answer"]) == undefined) {
      form.validateFields();
      return;
    }
    setCurrent(current + 1);
  };

  useEffect(() => {
    if (form) {
      console.log(_q.map((q) => q.data));
      form.setFieldsValue({
        data: _q.map((q) => q.data),
      });
    }
  }, [_q, _qx, form]);

  useEffect(() => {
    if (_qx.length && form) {
      form.setFieldsValue({
        questions: _qx.map((q) => ({
          answer: null,
        })),
      });
    }
  }, [form, _qx]);

  useEffect(() => {
    if (form && ApplicationId) {
      form.setFieldsValue({ ApplicationId });
    }
  }, [form, ApplicationId]);

  return (
    <Row>
      <Col span={10} offset={8}>
        <Card cover={<Header />} bordered={false}>
          <Spin spinning={cargando}>
            <Form form={form} layout="vertical" onFinish={send}>
              <Form.Item name="ApplicationId" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Form.List name="data">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={index}
                        noStyle
                        {...field}
                        fieldKey={[field.fieldKey]}
                        name={[field.name]}
                      >
                        <Input type="hidden" />
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              <Steps current={current} style={{ marginBottom: 32 }}>
                {_qx.map((q, i) => (
                  <Step key={i} title="" key={i}></Step>
                ))}
              </Steps>

              <Form.List name="questions">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <div
                        className={
                          current == index
                            ? "animate__animated animate__slideInUp question active"
                            : "animate__animated animate__slideInUp question"
                        }
                      >
                        <Form.Item
                          {...field}
                          fieldKey={[field.fieldKey, "answer"]}
                          name={[field.name, "answer"]}
                          label={label(index)}
                          rules={rules(index)}
                        >
                          <Radio.Group>
                            <Space direction="vertical">{options(index)}</Space>
                          </Radio.Group>
                        </Form.Item>

                        {index == _qx.length - 1 ? (
                          <Button htmlType="submit" type="primary">
                            Enviar respuestas
                          </Button>
                        ) : (
                          <Button
                            htmlType="button"
                            type="primary"
                            onClick={next}
                          >
                            Siguiente
                          </Button>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default Ida;
