import moment from "moment";
import { useContext, useEffect, useState } from "react";

const Timer = ({
  style = {},
  startSeconds = 0,
  type = 1,
  pause = false,
  onTick,
}) => {
  const [seconds, setSeconds] = useState(null);
  const [tick, setick] = useState(null);

  const loop = () => {
    setick((tick) => tick + type);
  };

  useEffect(() => {
    const interval = setInterval(() => loop(), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (tick && !pause) {
      setSeconds((seconds) => seconds + type);
    }
  }, [tick]);

  useEffect(() => {
    if (seconds) {
      onTick(seconds);
    }
  }, [seconds]);

  useEffect(() => {
    setSeconds(startSeconds);
  }, [startSeconds]);

  if (pause) {
  }

  return (
    <h1 className="countDown" style={{ ...style }}>
      {moment(seconds * 1000).format("mm:ss")}
    </h1>
  );
};

export default Timer;
